import 'react-vis/dist/style.css';
import '../styles/index.css';
import ReactGA from 'react-ga';

import React, { ReactElement } from 'react';
import Router from 'next/router';
import withGA from 'next-ga';
import * as Sentry from '@sentry/node';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN || '',
  release: `frontend@${process.env.RELEASE_ID}` || '',
});

function App({ Component, pageProps, err }): ReactElement {
  // Workaround for https://github.com/zeit/next.js/issues/8592
  return <Component {...pageProps} err={err} />;
}

export function reportWebVitals({
  id, name, label, value,
}): void {
  ReactGA.event({
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    action: name,
    label: id,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    nonInteraction: true,
  });
}

export default withGA('UA-154963830-1', Router)(App);
